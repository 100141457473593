import React from 'react'
import PropTypes from 'prop-types'
import PlayIcon from '../play-icon'
import ResponsiveImage from '../responsive-image'
import styles from './video-poster.module.scss'

const VideoPoster = ({ onClick, image, fillContainer, placeholder }) => (
  <div className={styles.el} data-fill-container={fillContainer}>
    <div className={styles.el__image}>
      {image.srcset || image.src ? (
        <ResponsiveImage {...image} fillContainer={fillContainer} />
      ) : (
        <>{placeholder && <div className={styles.el__imagePlaceholder} />}</>
      )}
      {onClick && (
        <button type="button" onClick={onClick} className={styles.el__play}>
          <div className={styles.el__play__icon}>
            <PlayIcon />
          </div>
        </button>
      )}
    </div>
  </div>
)

VideoPoster.defaultProps = {
  onClick: () => {},
  image: {},
  fillContainer: false,
  placeholder: true,
}

VideoPoster.propTypes = {
  onClick: PropTypes.func,
  image: PropTypes.object,
  fillContainer: PropTypes.bool,
  placeholder: PropTypes.bool,
}

export default VideoPoster
