import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './testimonial.module.scss'
import RichText from '../rich-text'
import ResponsiveImage from '../responsive-image'
import VideoPoster from '../video-poster'
import LightboxVideo from '../lightbox-video'

const Testimonial = ({
  video,
  image,
  quote,
  source,
  sourceImage,
  className,
}) => {
  const [open, setOpen] = useState(false)
  const classes = classNames(className, styles.el)

  return (
    <>
      {video && video.url && (
        <LightboxVideo
          videoURL={video.url}
          isOpen={open}
          onClose={() => {
            setOpen(false)
          }}
        />
      )}
      <blockquote className={classes}>
        <div className={styles.el__body}>
          {video && video.url ? (
            <VideoPoster
              image={image}
              onClick={() => {
                setOpen(true)
              }}
            />
          ) : (
            <>{(image.srcset || image.src) && <ResponsiveImage {...image} />}</>
          )}
          {quote && (
            <div className={styles.el__quote}>
              <RichText render={quote.raw} />
            </div>
          )}
        </div>
        <div className={styles.el__source}>
          {(sourceImage.srcset || sourceImage.src) && (
            <div className={styles.el__source__image}>
              <ResponsiveImage {...sourceImage} fillContainer />
            </div>
          )}
          <RichText render={source.raw} />
        </div>
      </blockquote>
    </>
  )
}

Testimonial.defaultProps = {
  video: {},
  image: {},
  sourceImage: {},
  quote: '',
  source: '',
  className: '',
}

Testimonial.propTypes = {
  video: PropTypes.object,
  image: PropTypes.object,
  sourceImage: PropTypes.object,
  quote: PropTypes.string,
  source: PropTypes.string,
  className: PropTypes.string,
}

export default Testimonial
