import React from 'react'
import PropTypes from 'prop-types'
import { chunk } from 'lodash-es'
import styles from './carousel-multi.module.scss'
import { Carousel, CarouselItem } from '../carousel'

const CarouselMulti = ({ items, colsDesktop, colsMobile, autoplay }) => {
  const containerColsD = 10
  const containerColsM = 4

  // group items into two
  const itemsGrouped = chunk(items, colsDesktop)
  const itemsOutput = []
  itemsGrouped.forEach((itemGroup, index) => {
    itemsOutput.push(
      <CarouselItem
        key={`carousel-multi-${index}`}
        className={styles.el__group}
      >
        {itemGroup.map(item => (
          <div
            key={item.id}
            className={styles.el__item}
            data-column-width-d={Math.floor(containerColsD / colsDesktop)}
            data-column-width-m={Math.floor(containerColsM / colsMobile)}
            data-column
          >
            {item.content}
          </div>
        ))}
      </CarouselItem>
    )
  })

  return (
    <Carousel
      autoplay={autoplay ? { delay: 8000 } : undefined}
      speed={800}
      loop={itemsOutput.length > colsDesktop}
      centeredSlides
      slidesPerView="auto"
      counter={false}
      noSwiping={itemsOutput.length <= 1}
    >
      {itemsOutput}
    </Carousel>
  )
}

CarouselMulti.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      content: PropTypes.node,
    })
  ),
  colsDesktop: PropTypes.number,
  colsMobile: PropTypes.number,
  autoplay: PropTypes.bool,
}

CarouselMulti.defaultProps = {
  items: [],
  colsDesktop: 2,
  colsMobile: 2,
  autoplay: false,
}

export default CarouselMulti
