import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import { Waypoint } from 'react-waypoint'
import styles from './featured-projects.module.scss'
import ImageFrameHover from '../../components/image-frame-hover'
import Arrow from '../../components/arrow'
import Link from '../../components/link'
import ResponsiveImage from '../../components/responsive-image'

const FeaturedProjectItem = ({ project }) => {
  const [isTransitioned, setIsTransitioned] = useState(false)
  const isClient = typeof window === 'object'

  const handleWaypointEnter = () => {
    setIsTransitioned(true)
  }

  return (
    <Waypoint
      onEnter={handleWaypointEnter}
      scrollableAncestor={isClient ? window : undefined}
    >
      <div className={styles.el__item} data-transition-in={isTransitioned}>
        <ImageFrameHover className={styles.el__image}>
          <Link to={project.route}>
            <span className={styles.el__image__inner}>
              <span className={styles.el__image__hover}>
                <span className={styles.el__image__filter}>
                  {(get(project, 'image.srcset') ||
                    get(project, 'image.src')) && (
                    <ResponsiveImage {...project.image} fillContainer />
                  )}
                </span>
              </span>
            </span>
            <span className={styles.el__image__overlay} />
          </Link>
        </ImageFrameHover>
        <div className={styles.el__item__text}>
          <h3 className={styles.el__item__heading}>
            {project.title}
            {project.short_summary && `— ${project.short_summary}`}
          </h3>
          <div className={styles.el__cta}>
            <Link to={project.route} className={styles.el__link}>
              <span className={styles.el__link__wrapper}>
                <span className={styles.el__link__text}>
                  View Project
                  <span className={styles.el__link__text__underline} />
                </span>
                <Arrow
                  className={styles.el__link__arrow}
                  barClassName={styles.el__link__arrow__bar}
                  endTopClassName={styles.el__link__arrow__top}
                  endBottomClassName={styles.el__link__arrow__bottom}
                />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </Waypoint>
  )
}

FeaturedProjectItem.propTypes = {
  project: PropTypes.object,
}

FeaturedProjectItem.defaultProps = {
  project: {},
}

export default FeaturedProjectItem
