import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './grid.module.scss'

export const GridColumn = ({ children, className, width }) => {
  const classes = classNames(styles.el__column, className)
  return (
    <div
      className={classes}
      data-column
      data-column-width={width ? width.toString().toLowerCase() : 'auto'}
    >
      {children}
    </div>
  )
}

GridColumn.defaultProps = {
  children: undefined,
  className: '',
  width: 'default',
}

GridColumn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  width: PropTypes.string,
}
