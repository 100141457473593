import React, { useEffect } from 'react'
import LazyLoad from 'vanilla-lazyload'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import objectFitImages from 'object-fit-images'
import styles from './responsive-image.module.scss'

const ResponsiveImage = ({
  src,
  srcset,
  sizes,
  placeholder,
  alt,
  lazyLoad,
  outerClass,
  outerStyle,
  imgClass,
  imgStyle,
  objectFit,
  objectPosition,
  fillContainer,
  aspectRatio,
}) => {
  const lazyLoadClass = lazyLoad ? 'lazy' : ''
  const isClient = typeof document === 'object'

  // only initialize lazy loading one time for the app
  if (isClient && !document.lazyLoadInstance && lazyLoad) {
    document.lazyLoadInstance = new LazyLoad({
      elements_selector: '.lazy',
      class_loaded: 'loaded',
      callback_loaded: el => {
        el.parentElement.setAttribute('data-loaded', 'true')
      },
    })

    objectFitImages()
  }

  // update lazyLoad after first rendering of every image
  useEffect(() => {
    if (isClient && lazyLoad) {
      document.lazyLoadInstance.update()
    }
  }, [])

  useEffect(() => {
    if (isClient && lazyLoad) {
      document.lazyLoadInstance.update()
    }
  })

  const objectFitPolyfill = {
    fontFamily: `'object-fit: ${objectFit}; object-position: ${objectPosition}'`,
  }

  const dataSrcAttr = {}
  if (lazyLoad) {
    dataSrcAttr['data-src'] = src
    if (srcset) {
      dataSrcAttr['data-srcset'] = srcset
    }
  } else {
    dataSrcAttr.src = src
  }

  const dataSrcSetAttr = {}
  if (sizes) {
    dataSrcSetAttr['data-sizes'] = sizes
  }

  return (
    <div
      className={classNames(styles.el, outerClass)}
      style={{
        paddingTop:
          aspectRatio && !fillContainer ? `${100 / aspectRatio}%` : null,
        ...outerStyle,
      }}
      data-fill-container={fillContainer}
    >
      <img
        alt={alt}
        className={classNames(styles.el__img, lazyLoadClass, imgClass)}
        data-fill-container={!!aspectRatio}
        style={{ objectFit, objectPosition, ...objectFitPolyfill, ...imgStyle }}
        {...dataSrcSetAttr}
        {...dataSrcAttr}
      />
      {placeholder && (
        <img src={placeholder} alt="" className={styles.el__placeholder} />
      )}
    </div>
  )
}

ResponsiveImage.defaultProps = {
  src: '',
  srcset: '',
  sizes: '',
  placeholder: '',
  alt: '',
  lazyLoad: true,
  outerClass: '',
  outerStyle: {},
  imgClass: '',
  imgStyle: {},
  objectFit: 'cover',
  objectPosition: 'center',
  fillContainer: false,
  aspectRatio: undefined,
}

ResponsiveImage.propTypes = {
  src: PropTypes.string,
  srcset: PropTypes.string,
  sizes: PropTypes.string,
  placeholder: PropTypes.string,
  alt: PropTypes.string,
  lazyLoad: PropTypes.bool,
  outerClass: PropTypes.string,
  outerStyle: PropTypes.object,
  imgClass: PropTypes.string,
  imgStyle: PropTypes.object,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  fillContainer: PropTypes.bool,
  aspectRatio: PropTypes.number,
}

export default ResponsiveImage
