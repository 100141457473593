import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './text-and-image.module.scss'
import Section from '../../components/section'
import Container from '../../components/container'
import RichText from '../../components/rich-text'
import Button from '../../components/button'
import ResponsiveImage from '../../components/responsive-image'
import VideoPoster from '../../components/video-poster'
import LightboxVideo from '../../components/lightbox-video'
import Indent from '../../components/indent'

const Media = ({ images, video, setOpen, fullColumn }) => {
  let className = null
  if (!fullColumn) {
    className = styles.el__image_outer_icon_width_image
  }

  return (
    <div className={classNames(styles.el__image_outer, className)}>
      {images.map(image => {
        return (
          <ResponsiveImage
            {...image}
            key={image.id}
            outerClass={styles.el__image}
          />
        )
      })}
      {video && video.url && (
        <VideoPoster
          onClick={() => {
            setOpen(true)
          }}
          placeholder=''
          fillContainer
        />
      )}
    </div >
  )
}

Media.defaultProps = {
  images: [],
  video: null,
  fullColumn: false
}

Media.propTypes = {
  images: PropTypes.array,
  video: PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  fullColumn: PropTypes.bool
}

const TextAndImageSection = ({
  heading,
  bodyText,
  fullColumnImage,
  images,
  cta,
  layout,
  video,
}) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      {video && video.url && (
        <LightboxVideo
          videoURL={video.url}
          isOpen={open}
          onClose={() => {
            setOpen(false)
          }}
        />
      )}
      <Section className={styles.el} topPadding={false} bottomPadding={false}>
        <Container>
          <Indent className={styles.el__container}>
            <div
              className={styles.el__side}
              data-order={layout === 'image-first' ? 1 : 2}
            >
              {fullColumnImage && <Media images={images} video={video} setOpen={setOpen} fullColumn />}
            </div>
            <div
              className={styles.el__main}
              data-order={layout === 'text-first' ? 1 : 2}
            >
              {!fullColumnImage && <Media images={images.map(img => img.icon)} video={video} setOpen={setOpen} fullColumn={false} />}
              <div className={styles.el__text_outer}>
                {heading && <h3 className={styles.el__heading}>{heading}</h3>}
                <div className={styles.el__bodyText}>
                  <RichText render={bodyText.raw} />
                </div>
                {cta && cta.label && cta.link && (
                  <div className={styles.el__button}>
                    <Button link={cta.link}>{cta.label}</Button>
                  </div>
                )}
              </div>
            </div>
          </Indent>
        </Container>
      </Section>
    </>
  )
}

TextAndImageSection.propTypes = {
  heading: PropTypes.string,
  bodyText: PropTypes.shape({
    raw: PropTypes.array.isRequired,
    html: PropTypes.string,
  }),
  fullColumnImage: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.PropTypes.object),
  cta: PropTypes.object,
  layout: PropTypes.string,
  video: PropTypes.object,
}

TextAndImageSection.defaultProps = {
  heading: '',
  fullColumnImage: false,
  bodyText: null,
  images: [],
  cta: {},
  layout: '',
  video: {},
}

export default TextAndImageSection
