import React from 'react'
import PropTypes from 'prop-types'
import styles from './flexible.module.scss'
import SectionContainer from '../../components/section-container'

const FlexibleTemplate = ({ sections, borderBottom, topPadding }) => {
  return (
    <div className={styles.el} data-top-padding={topPadding}>
      <SectionContainer sections={sections} />
      {borderBottom && <div className={styles.el__border} />}
    </div>
  )
}

FlexibleTemplate.defaultProps = {
  sections: [],
  borderBottom: false,
  topPadding: true,
}

FlexibleTemplate.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  borderBottom: PropTypes.bool,
  topPadding: PropTypes.bool,
}

export default FlexibleTemplate
