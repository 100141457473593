import { get } from 'lodash-es'

export const IMAGE_TYPE_FLUID = 'fluid'
export const IMAGE_TYPE_FIXED = 'fixed'

export const getResponsiveImage = (data, type = IMAGE_TYPE_FLUID) => {
  let imageObject
  if (type === IMAGE_TYPE_FLUID) {
    imageObject = get(data, 'localFile.childImageSharp.fluid')
  } else if (type === IMAGE_TYPE_FIXED) {
    imageObject = get(data, 'localFile.childImageSharp.fixed')
  }

  if (imageObject) {
    const width = imageObject.width || get(data, 'dimensions.width')
    const height = imageObject.height || get(data, 'dimensions.height')
    return {
      id: get(data, 'localFile.id'),
      type,
      src: imageObject.src || get(data, 'src'),
      srcset: type === IMAGE_TYPE_FLUID ? imageObject.srcSet : null,
      sizes: type === IMAGE_TYPE_FLUID ? imageObject.sizes : null,
      aspectRatio: imageObject.aspectRatio || width / height,
      placeholder: imageObject.base64,
      alt: data.alt,
      width,
      height,
    }
  }

  if (get(data, 'url')) {
    // This is likely coming from preview
    const width = get(data, 'dimensions.width')
    const height = get(data, 'dimensions.height')
    return {
      type,
      id: get(data, 'url'),
      src: get(data, 'url'),
      width,
      height,
      aspectRatio: width / height
    }
  }

  // eslint-disable-next-line no-console
  // console.warn(`Couldn't find ${type} image object in data param`, data)

  return null
}
