import React from 'react'
import PropTypes from 'prop-types'
import styles from './blog-posts-carousel.module.scss'
import BlogPostThumb from '../../components/blog-post-thumb'
import CarouselMulti from '../../components/carousel-multi'
import Container from '../../components/container'
import Section from '../../components/section'
import Indent from '../../components/indent'

const BlogPostsCarouselSection = ({ posts, heading, next, prev }) => {
  const postsOutput = []
  posts.forEach(post => {
    postsOutput.push({
      id: post.id,
      content: (
        <BlogPostThumb
          title={post.title}
          url={post.route}
          image={post.image}
          date={post.date}
        />
      ),
    })
  })

  return (
    <Section className={styles.el} prev={prev} next={next}>
      <div className={styles.el__inner}>
        {heading && (
          <Container>
            <Indent>
              <h3 className={styles.el__heading}>{heading}</h3>
            </Indent>
          </Container>
        )}
        {!!postsOutput.length && (
          <CarouselMulti
            items={postsOutput}
            colsDesktop={2}
            colsMobile={2}
            autoplay
          />
        )}
      </div>
    </Section>
  )
}

BlogPostsCarouselSection.propTypes = {
  heading: PropTypes.string,
  posts: PropTypes.arrayOf(PropTypes.PropTypes.object),
  next: PropTypes.string,
  prev: PropTypes.string,
}

BlogPostsCarouselSection.defaultProps = {
  posts: [],
  heading: '',
  next: '',
  prev: '',
}

export default BlogPostsCarouselSection
