import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './image-credit.module.scss'

const ImageCredit = ({ className, children }) => {
  const classes = classNames(styles.el, className)

  return <div className={classes}>{children}</div>
}

ImageCredit.defaultProps = {
  className: undefined,
  children: undefined,
}

ImageCredit.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default ImageCredit
