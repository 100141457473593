import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import classNames from 'classnames'
import styles from './section.module.scss'

const Section = ({
  children,
  className,
  topPadding,
  bottomPadding,
  prev,
  next,
  scrollEnabled,
  transitionIn,
  transitionDelay,
}) => {
  const classes = classNames(styles.el, className)
  const [isTransitioned, setIsTransitioned] = useState(false)

  const handleTransitionIn = () => {
    transitionIn()
    setIsTransitioned(true)
  }

  useEffect(() => {
    if (!scrollEnabled) {
      const timer = setTimeout(() => {
        handleTransitionIn()
      }, transitionDelay)
      return () => clearTimeout(timer)
    }
    return undefined
  }, [])

  const section = (
    <section
      className={classes}
      data-top-padding={topPadding}
      data-bottom-padding={bottomPadding}
      data-next={next}
      data-prev={prev}
      data-transition-in={isTransitioned}
    >
      {children}
    </section>
  )

  if (scrollEnabled) {
    return (
      <Waypoint
        handleScrollEnter={transitionIn}
        scrollableAncestor={typeof window === 'object' ? window : undefined}
      >
        {section}
      </Waypoint>
    )
  }

  return <>{section}</>
}

Section.defaultProps = {
  children: undefined,
  className: undefined,
  topPadding: true,
  bottomPadding: true,
  prev: '',
  next: '',
  scrollEnabled: false,
  transitionIn: () => {},
  transitionDelay: 750,
}

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  topPadding: PropTypes.bool,
  bottomPadding: PropTypes.bool,
  prev: PropTypes.string,
  next: PropTypes.string,
  scrollEnabled: PropTypes.bool,
  transitionIn: PropTypes.func,
  transitionDelay: PropTypes.number,
}

export default Section
