import React from 'react'
import PropTypes from 'prop-types'
import { RichText, Elements } from 'prismic-reactjs'

import styles from './body-text.module.scss'
import Container from '../../components/container'
import Section from '../../components/section'
import Indent from '../../components/indent'
import { linkResolver } from '../../prismic/utils/link-resolver'
import { withHtmlSerializerObfuscator } from '../../utils/html-serializer'

const htmlSerializer = (type, element, content, children, key) => {
  if (type === Elements.label) {
    if (element.data.label === 'map') {
      return (
        <iframe
          key={key}
          title={`${element.data.label}-${key}`}
          width="100%"
          height="450"
          frameBorder="0"
          className={styles.el__map}
          src={content}
          allowFullScreen
        />
      )
    }
  }
  return null
}

const BodyTextSection = ({ textContent }) => {
  return (
    <Section className={styles.el}>
      <Container>
        <Indent className={styles.el__container}>
          <div className={styles.el__side} />
          <div className={styles.el__main}>
            <div className={styles.el__text_content}>
              <RichText
                render={textContent.raw}
                linkResolver={linkResolver}
                htmlSerializer={withHtmlSerializerObfuscator(htmlSerializer)}
              />
            </div>
          </div>
        </Indent>
      </Container>
    </Section>
  )
}

BodyTextSection.propTypes = {
  textContent: PropTypes.object.isRequired,
}

export default BodyTextSection
