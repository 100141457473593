import React from 'react'
import PropTypes from 'prop-types'

import BigImageSection from '../../sections/big-image'
import BlogPostsCarouselSection from '../../sections/blog-posts-carousel'
import BlogPostsGridSection from '../../sections/blog-posts-grid'
import BodyTextSection from '../../sections/body-text'
import ContactSection from '../../sections/contact'
import FeaturedProjectsSection from '../../sections/featured-projects'
import HeroSection from '../../sections/hero'
import IntroSection from '../../sections/intro'
import LogosSection from '../../sections/logos'
import MultiImageLayoutSection from '../../sections/multi-image-layout'
import ProfilesGridSection from '../../sections/profiles-grid'
import TestimonialsSection from '../../sections/testimonials'
import TextAccordionSection from '../../sections/text-accordion'
import TextAndImageSection from '../../sections/text-and-image'

const SectionContainer = ({ sections }) => {
  const sectionTypes = {
    big_image: BigImageSection,
    blog_posts_carousel: BlogPostsCarouselSection,
    blog_posts_grid: BlogPostsGridSection,
    body_text: BodyTextSection,
    contact: ContactSection,
    featured_projects: FeaturedProjectsSection,
    hero: HeroSection,
    intro: IntroSection,
    logos: LogosSection,
    multi_image_layout: MultiImageLayoutSection,
    profiles: ProfilesGridSection,
    testimonials: TestimonialsSection,
    text_accordion: TextAccordionSection,
    text_and_image: TextAndImageSection,
  }

  const output = []
  let prevSection = 'none'
  let nextSection = ''

  sections.forEach((section, index) => {
    const sectionType = section.type
    const SectionType = sectionTypes[sectionType]
    if (sections[index + 1]) {
      nextSection = sections[index + 1].type || sections[index + 1].slice_type
    } else {
      nextSection = 'none'
    }
    if (SectionType) {
      const propsOutput = section
      output.push(
        <SectionType
          key={section.id}
          prev={prevSection}
          next={nextSection}
          scrollEnabled={index !== 0}
          {...propsOutput}
        />
      )

      prevSection = sectionType
    }
  })

  return <div>{output}</div>
}

SectionContainer.defaultProps = {
  sections: [],
}

SectionContainer.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
}

export default SectionContainer
