import React from 'react'
import PropTypes from 'prop-types'
import styles from './logos.module.scss'
import Section from '../../components/section'
import Container from '../../components/container'
import CarouselMulti from '../../components/carousel-multi'
import Indent from '../../components/indent'

const LogosSection = ({ heading, items }) => {
  const itemsOutput = []
  items.forEach(item => {
    const logo = (
      <div
        className={styles.el__items__logo}
        style={{
          backgroundImage: `url(${item.logo.url})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        aria-label={item.title}
      />
    )

    itemsOutput.push({
      id: item.id,
      content: (
        <div className={styles.el__item}>
          {item.link && item.link.url ? (
            <a
              href={item.link.url}
              target={item.link.url}
              aria-label={item.title}
            >
              {logo}
            </a>
          ) : (
            logo
          )}
        </div>
      ),
    })
  })

  return (
    <Section className={styles.el}>
      <h3 className={styles.el__heading}>
        <Container>
          <Indent>{heading}</Indent>
        </Container>
      </h3>
      {!!items.length && (
        <div className={styles.el__items}>
          <div className={styles.el__items__inner}>
            <CarouselMulti items={itemsOutput} colsDesktop={5} colsMobile={4} />
          </div>
        </div>
      )}
    </Section>
  )
}

LogosSection.defaultProps = {
  heading: '',
  items: [],
}

LogosSection.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      logo: PropTypes.object,
      title: PropTypes.string,
    })
  ),
}

export default LogosSection
