import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './carousel.module.scss'

// wrapper for swiper component
// docs: https://swiperjs.com/api/

export const Carousel = props => {
  const { children, className } = props
  const classes = classNames(styles.el, className)

  // extend inherited props
  return <div className={classes}>{children}</div>
}

Carousel.defaultProps = {
  children: undefined,
  className: '',
}

Carousel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
