import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './carousel.module.scss'

export const CarouselItem = ({ children, className }) => {
  const classes = classNames(className, styles.el__item)
  return <div className={classes}>{children}</div>
}

CarouselItem.defaultProps = {
  children: undefined,
  className: '',
}

CarouselItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
