import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import superagent from 'superagent'
import PropTypes from 'prop-types'
import slugify from 'slugify'
import styles from './form.module.scss'

const asyncSubmit = (location, data) => {
  const url = location.pathname

  return new Promise((resolve, reject) => {
    superagent
      .post(url)
      .send(data)
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .end((err, res) => {
        if (err) {
          console.warn(err) // eslint-disable-line no-console
          reject()
        } else if (
          res.text.indexOf('Your form submission has been received') !== -1
        ) {
          resolve()
        } else {
          reject(new Error('Invalid response from server'))
        }
      })
  })
    .then(() => {
      return true
    })
    .catch(error => {
      console.log('Error:', error) // eslint-disable-line no-console
      return false
    })
}

const Form = ({
  id,
  inputs,
  submitLabel,
  classOverrides,
  submitCallback,
  errorCallback,
  customSubmit,
  location,
}) => {
  // setup classes
  const defaultClasses = {
    form: styles.el,
    fieldContainer: styles.el__fieldContainer,
    field: styles.el__field,
    fieldInput: styles.el__field__input,
    fieldLabel: styles.el__field__label,
    fieldError: styles.el__field__error,
    submit: styles.el__button,
  }
  const classes = { ...defaultClasses, ...classOverrides }

  // initialise the react-hook-form
  const { register, handleSubmit, errors } = useForm()
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async data => {
    setSubmitting(true)
    const payload = { 'form-name': id, ...data }
    const success = await asyncSubmit(location, payload) // post via Ajax
    setSubmitting(false)

    // tell the parent we've submitted or theres an error
    if (success) {
      submitCallback()
    } else {
      errorCallback()
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.form}
      name={id}
      method="post"
      data-submitting={submitting}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <div className={classes.fieldContainer}>
        <input type="hidden" name="form-name" value={id} />
        {inputs.map(input => {
          const { name, label, type, required, options } = input
          return (
            <div className={classes.field} key={input.id} data-type={type}>
              <label htmlFor={name} className={classes.fieldLabel}>
                {label}
                {required ? '*' : null}
              </label>
              {type === 'textarea' ? (
                <textarea
                  name={name}
                  id={name}
                  ref={register({ required })}
                  className={classes.fieldInput}
                />
              ) : null}
              {type === 'text' ? (
                <input
                  type={type}
                  name={name}
                  id={name}
                  ref={register({ required })}
                  className={classes.fieldInput}
                />
              ) : null}
              {type === 'email' ? (
                <input
                  type={type}
                  name={name}
                  id={name}
                  ref={register({
                    required,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                  className={classes.fieldInput}
                />
              ) : null}
              {type === 'select' && options ? (
                <select
                  name={name}
                  id={name}
                  ref={register({ required })}
                  className={classes.fieldInput}
                >
                  {options.map(option => {
                    // Convert text to a variable name
                    const value = slugify(option, {
                      lower: true,
                    })
                    return (
                      <option key={`${name}-${value}`} value={value}>
                        {option}
                      </option>
                    )
                  })}
                </select>
              ) : null}

              {errors[name] && (
                <div className={classes.fieldError}>
                  {errors[name].type === 'required'
                    ? `Required Field`
                    : errors[name].message}
                </div>
              )}
            </div>
          )
        })}
      </div>

      {customSubmit === undefined ? (
        <button type="submit" className={classes.submit}>
          {submitLabel}
        </button>
      ) : (
        <>{customSubmit}</>
      )}
    </form>
  )
}

Form.defaultProps = {
  id: '',
  inputs: [],
  submitLabel: '',
  classOverrides: {},
  customSubmit: undefined,
  location: {},
  submitCallback: () => {},
  errorCallback: () => {},
}

Form.propTypes = {
  id: PropTypes.string,
  inputs: PropTypes.array,
  submitLabel: PropTypes.string,
  classOverrides: PropTypes.object,
  customSubmit: PropTypes.node,
  submitCallback: PropTypes.func,
  errorCallback: PropTypes.func,
  location: PropTypes.object,
}

export default Form
