import React from 'react'
import PropTypes from 'prop-types'
import styles from './play-icon.module.scss'
import PlayIconSVG from './images/play-icon.inline.svg'

const PlayIcon = ({ onClick }) => {
  const Tag = onClick ? 'button' : 'span'

  return (
    <Tag className={styles.el} onClick={onClick} role={onClick ? 'button' : ''}>
      <span className={styles.el__icon__bg} />
      <span className={styles.el__icon}>
        <PlayIconSVG />
      </span>
    </Tag>
  )
}

PlayIcon.defaultProps = {
  onClick: undefined,
}
PlayIcon.propTypes = {
  onClick: PropTypes.func,
}
export default PlayIcon
