import React from 'react'
import PropTypes from 'prop-types'
import styles from './blog-posts-grid.module.scss'
import { Grid, GridRow, GridColumn } from '../../components/grid'
import BlogPostThumb from '../../components/blog-post-thumb'
import Container from '../../components/container'
import Section from '../../components/section'

const BlogPostsGridSection = ({ posts, heading }) => {
  return (
    <Section className={styles.el}>
      {heading && (
        <Container>
          <h3 className={styles.el__heading}>{heading}</h3>
        </Container>
      )}
      <Grid className={styles.el__grid}>
        <GridRow>
          {posts.map(post => (
            <GridColumn key={post.id} width="4" className={styles.el__column}>
              <BlogPostThumb
                title={post.title}
                url={post.route}
                image={post.image}
                date={post.date}
              />
            </GridColumn>
          ))}
        </GridRow>
      </Grid>
    </Section>
  )
}

BlogPostsGridSection.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.PropTypes.object),
  heading: PropTypes.string,
}

BlogPostsGridSection.defaultProps = {
  posts: [],
  heading: '',
}

export default BlogPostsGridSection
