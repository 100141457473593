import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import styles from './hero-slide.module.scss'
import Link from '../../components/link'
import ResponsiveImage from '../../components/responsive-image'
import RichText from '../../components/rich-text'
import Indent from '../../components/indent'
import Container from '../../components/container'

// Add staggering effect to the children of the container
const containerVariants = {
  before: {},
  after: { transition: { staggerChildren: 0.02, delayChildren: 1.2 } },
}

// Variants for animating each letter
const letterVariants = {
  before: {
    opacity: 0,
    // y: 60,
    transition: {
      duration: 0.5,
    },
  },
  after: {
    opacity: 1,
    // y: 0,
    transition: {
      duration: 0.5,
    },
  },
}

const getStyleFromRichHeading = (headingFormat, position) => {
  let style = {}
  if (!headingFormat) {
    return style
  }

  if (headingFormat.spans.length > 0) {
    const span = headingFormat.spans[0]
    if (position >= span.start && position <= span.end) {
      if (span.type === 'strong') {
        style = 'font_weight_bold'
      }
    }
  }
  return style
}

const HeroSlide = ({
  image,
  imageAlign,
  imageMobile,
  heading,
  description,
  cta,
}) => {
  let imageAlignOutput = 'center'
  if (imageAlign === 'bottom') {
    imageAlignOutput = 'center bottom'
  } else if (imageAlign === 'top') {
    imageAlignOutput = 'center top'
  }

  const imageParams = {
    ...image,
    objectPosition: imageAlignOutput,
    fillContainer: true,
  }

  const imageMobileParams = {
    ...imageMobile,
    fillContainer: true,
  }

  const textOutput = useMemo(
    h => {
      const words = []
      let headingContent = h
      let headingFormat
      if (heading.html) {
        headingContent = heading.text
        headingFormat = heading.raw.length > 0 ? heading.raw[0] : null
      }

      if (headingContent) {
        const wordArray = headingContent.trim().split(' ')
        if (wordArray.length) {
          let position = -1
          wordArray.forEach((string, wordIndex) => {
            const letters = Array.from(string)
            words.push(
              letters.map(letter => {
                position += 1
                return {
                  value: letter,
                  style: getStyleFromRichHeading(headingFormat, position),
                }
              })
            )

            if (wordIndex + 1 !== wordArray.length) {
              position += 1
              words.push([{ value: '\u00A0' }])
            }
          })
        }
      }
      return words
    },
    [heading]
  )

  return (
    <div className={styles.el}>
      {(imageParams.srcset || imageParams.src) && (
        <div
          className={styles.el__image}
          data-desktop-only={
            imageMobileParams.srcset || imageMobileParams.src ? 'true' : 'false'
          }
        >
          <ResponsiveImage {...imageParams} />
        </div>
      )}
      {(imageMobileParams.srcset || imageMobileParams.src) && (
        <div className={styles.el__image} data-mobile-only="true">
          <ResponsiveImage {...imageMobileParams} />
        </div>
      )}
      <Container className={styles.el__text}>
        <Indent className={styles.el__text__container}>
          <div className={styles.el__side} />
          <div className={styles.el__main}>
            {heading && (
              <motion.h1
                className={styles.el__heading}
                variants={containerVariants}
                initial="before"
                animate="after"
              >
                {textOutput.map((word, wordIndex) => {
                  return (
                    <span
                      key={`heading-${wordIndex}`}
                      className={styles.el__heading__word}
                    >
                      {word.map((letter, letterIndex) => {
                        const letterClassName =
                          letter.value === '\u00A0'
                            ? styles.el__heading__space
                            : styles.el__heading__letter

                        return (
                          <motion.span
                            key={`heading-${wordIndex}-${letterIndex}`}
                            className={classNames(
                              letterClassName,
                              styles[`el__heading__${letter.style}`]
                            )}
                            variants={letterVariants}
                          >
                            {letter.value}
                          </motion.span>
                        )
                      })}
                    </span>
                  )
                })}
              </motion.h1>
            )}
            {description.text && (
              <div className={styles.el__description}>
                <RichText render={description.raw} />
              </div>
            )}
            {get(cta, 'url') && (
              <div className={styles.el__links}>
                <Link to={cta.url} target={cta.target}>
                  {cta.label || 'Read More'}
                </Link>
              </div>
            )}
          </div>
        </Indent>
      </Container>
    </div>
  )
}

HeroSlide.defaultProps = {
  image: {},
  imageAlign: 'default',
  imageMobile: {},
  heading: '',
  description: '',
  cta: {},
}

HeroSlide.propTypes = {
  image: PropTypes.object,
  imageAlign: PropTypes.string,
  imageMobile: PropTypes.object,
  heading: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
    raw: PropTypes.array.isRequired,
  }),
  description: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string,
    raw: PropTypes.array.isRequired,
  }),
  cta: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
  }),
}

export default HeroSlide
