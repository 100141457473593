import React from 'react'
import PropTypes from 'prop-types'

import styles from './multi-image-layout.module.scss'
import Container from '../../components/container'
import Section from '../../components/section'
import ResponsiveImage from '../../components/responsive-image'
import ImageCredit from '../../components/image-credit'
import RichText from '../../components/rich-text'

const MultiImageLayoutSection = ({ images, align }) => {
  let verticalAlign = 'default'

  if (images.length === 2) {
    if (images[0].align === 'top' && images[1].align === 'bottom') {
      images[1].offset = `${(images[0].image.height / images[0].image.width) *
        50}%`
    } else if (images[0].align === 'bottom' && images[1].align === 'top') {
      images[0].offset = `${(images[1].image.height / images[1].image.width) *
        50}%`
    } else if (images[0].align === 'bottom' && images[1].align === 'bottom') {
      verticalAlign = 'bottom'
    } else if (images[0].align === 'middle' && images[1].align === 'middle') {
      verticalAlign = 'middle'
    }
  }

  return (
    <Section className={styles.el}>
      <div className={styles.el__inner}>
        <Container>
          <div
            className={styles.el__container}
            data-vertical-align={verticalAlign}
            data-horizontal-align={align}
            data-images={images.length}
          >
            {images.map(item => {
              const imageParams = { ...item.image }
              return (
                <div
                  className={styles.el__column}
                  data-align={item.align}
                  key={item.id}
                >
                  {(imageParams.srcset || imageParams.src) && (
                    <>
                      {item.offset && (
                        <div
                          className={styles.el__column__spacer}
                          style={{ paddingTop: item.offset }}
                        />
                      )}
                      <button
                        type="button"
                        onClick={item.image.onClick}
                        className={styles.el__imageLink}
                      >
                        <ResponsiveImage {...imageParams} />
                      </button>
                      {item.credit && (
                        <ImageCredit>
                          Image: <RichText render={item.credit.raw} />
                        </ImageCredit>
                      )}
                    </>
                  )}
                </div>
              )
            })}
          </div>
        </Container>
      </div>
    </Section>
  )
}

MultiImageLayoutSection.defaultProps = {
  images: [],
  align: 'default',
}

MultiImageLayoutSection.propTypes = {
  images: PropTypes.array,
  align: PropTypes.string,
}

export default MultiImageLayoutSection
