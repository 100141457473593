import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styles from './contact.module.scss'
import RichText from '../../components/rich-text'
import Form from '../../components/form'
import Button from '../../components/button'
import Section from '../../components/section'
import Container from '../../components/container'

const ContactSection = ({
  formInputs,
  submitLabel,
  location,
  confirmationText,
}) => {
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)

  return (
    <Section className={styles.el}>
      <Container>
        {submitted && (
          <div className={styles.el__confirmation}>
            <RichText render={confirmationText.raw} />
          </div>
        )}
        {error && (
          <div className={styles.el__confirmation}>
            There was a problem submitting the form, please check the fields and
            try again.
          </div>
        )}
        {!submitted && (
          <Form
            id="ContactSection"
            inputs={formInputs}
            submitLabel={submitLabel}
            location={location}
            classOverrides={{
              form: styles.el__form,
              field: styles.el__form__field,
              fieldLabel: styles.el__form__field__label,
              fieldInput: styles.el__form__field__input,
              fieldError: styles.el__form__field__error,
              fieldContainer: styles.el__form__fieldContainer,
            }}
            customSubmit={
              <div className={styles.el__form__buttons}>
                <Button type="submit" Tag="button" align="right">
                  Submit
                </Button>
              </div>
            }
            submitCallback={() => {
              setSubmitted(true)
              setError(false)
            }}
            errorCallback={() => {
              setError(true)
            }}
          />
        )}
      </Container>
    </Section>
  )
}

ContactSection.defaultProps = {
  formInputs: [],
  submitLabel: 'Submit',
  location: {},
  confirmationText: 'Thank you for getting in touch!',
}

ContactSection.propTypes = {
  formInputs: PropTypes.array,
  submitLabel: PropTypes.string,
  location: PropTypes.object,
  confirmationText: PropTypes.string,
}

export default ContactSection
