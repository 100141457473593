import React from 'react'
import PropTypes from 'prop-types'
import Link from '../link'
import ResponsiveImage from '../responsive-image'
// import ImageFrameHover from '../image-frame-hover'
import styles from './blog-post-thumb.module.scss'

const BlogPostThumb = ({ title, url, image, date }) => {
  return (
    <div className={styles.el}>
      <div className={styles.el__image}>
        {image && (image.srcset || image.src) && (
          <span className={styles.el__image__inner}>
            <span className={styles.el__image__hover}>
              <span className={styles.el__image__filter}>
                <Link to={url}>
                  <ResponsiveImage {...image} />
                </Link>
              </span>
            </span>
          </span>
        )}
        <span className={styles.el__image__overlay} />
      </div>
      <div className={styles.el__text}>
        <Link to={url}>
          {date && <>{date} — </>}
          {title}
        </Link>
      </div>
    </div>
  )
}

BlogPostThumb.defaultProps = {
  title: '',
  url: '',
  image: {},
  date: '',
}

BlogPostThumb.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.object,
  date: PropTypes.string,
}

export default BlogPostThumb
