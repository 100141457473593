import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './lightbox-video.module.scss'
import Lightbox from '../lightbox'
import Video from '../video'

const LightboxVideo = ({ videoURL, isOpen, onClose, muted, loop }) => {
  const [playing, setPlaying] = useState(false)
  const playbackDelay = 750

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setPlaying(isOpen)
      }, playbackDelay) // delay video playback
    } else {
      setPlaying(isOpen)
    }
  }, [isOpen])

  return (
    <Lightbox isOpen={isOpen} onClose={onClose}>
      <div className={styles.el}>
        <Video
          src={videoURL}
          playing={playing}
          muted={muted}
          loop={loop}
          maxWidth={1000}
          aspectRatio={0.5625}
          controls
        />
      </div>
    </Lightbox>
  )
}

LightboxVideo.defaultProps = {
  videoURL: '',
  isOpen: false,
  onClose: () => {},
  muted: false,
  loop: false,
}

LightboxVideo.propTypes = {
  videoURL: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
}

export default LightboxVideo
