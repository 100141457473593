import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import RichText from '../rich-text'
import ExpandSVG from './images/expand.inline.svg'
import CollapseSVG from './images/collapse.inline.svg'
import styles from './accordion-item.module.scss'

const AccordionItem = ({ id, open, setOpen, heading, content }) => {
  // https://codesandbox.io/s/framer-motion-accordion-qx958
  // this approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.

  const isOpen = id === open
  // const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setOpen(isOpen ? false : id)
    // setIsOpen(!isOpen)
  }

  return (
    <div className={styles.el} data-open={isOpen}>
      <motion.button
        type="button"
        className={styles.el__head}
        onClick={handleToggle}
        initial={false}
      >
        <h4 className={styles.el__heading}>{heading}</h4>
        <div className={styles.el__icon}>
          <motion.div
            className={styles.el__icon__expand}
            animate={isOpen ? 'hidden' : 'visible'}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            transition={{ duration: 0.2 }}
          >
            <ExpandSVG />
          </motion.div>
          <motion.div
            className={styles.el__icon__collapse}
            animate={isOpen ? 'visible' : 'hidden'}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            transition={{ duration: 0.2 }}
          >
            <CollapseSVG />
          </motion.div>
        </div>
      </motion.button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            className={styles.el__content}
          >
            <div className={styles.el__content__inner}>
              <RichText render={content.raw} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

AccordionItem.defaultProps = {
  id: undefined,
  open: false,
  setOpen: undefined,
  heading: '',
  content: '',
}

AccordionItem.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  heading: PropTypes.string,
  content: PropTypes.string,
}

AccordionItem.propTypes = {}

export default AccordionItem
