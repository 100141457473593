import React, { Suspense } from 'react'

import PropTypes from 'prop-types'

import styles from './video.module.scss'

const ReactPlayer = React.lazy(() => import('react-player'))

const Video = ({
  src,
  playing,
  muted,
  loop,
  maxWidth,
  aspectRatio,
  controls,
  fillContainer,
}) => {
  const spacer = aspectRatio ? `${aspectRatio * 100}%` : undefined

  return (
    <div
      className={styles.el}
      style={{
        maxWidth: maxWidth ? `${maxWidth}px` : '',
        width: maxWidth ? '100%' : undefined,
      }}
      data-fill-container={fillContainer}
    >
      {spacer && <div style={{ paddingTop: spacer }} />}
      <Suspense fallback={<div />}>
        <ReactPlayer
          className={
            aspectRatio || fillContainer
              ? styles.el__videoFill
              : styles.el__video
          }
          url={src}
          playing={playing}
          muted={muted}
          loop={loop}
          width={maxWidth || fillContainer ? '100%' : 'auto'}
          height={maxWidth || fillContainer ? '100%' : 'auto'}
          controls={controls}
        />
      </Suspense>
    </div>
  )
}

Video.defaultProps = {
  src: '',
  playing: false,
  muted: false,
  loop: false,
  maxWidth: undefined,
  aspectRatio: undefined,
  controls: true,
  fillContainer: false,
}

Video.propTypes = {
  src: PropTypes.string,
  playing: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  maxWidth: PropTypes.number,
  aspectRatio: PropTypes.number,
  controls: PropTypes.bool,
  fillContainer: PropTypes.bool,
}

export default Video
