import React from 'react'
import PropTypes from 'prop-types'

import styles from './testimonials.module.scss'
import Section from '../../components/section'
import Container from '../../components/container'
import Indent from '../../components/indent'
import Testimonial from '../../components/testimonial'
import RichText from '../../components/rich-text'

const TestimonialsSection = ({ heading, items }) => {
  return (
    <Section className={styles.el}>
      <Container>
        <h3 className={styles.el__heading}>
          <Indent>
            <RichText render={heading} />
          </Indent>
        </h3>
        <div className={styles.el__items}>
          {items.map(item => (
            <div className={styles.el__item} key={item.id}>
              <Testimonial
                video={item.video}
                image={{ ...item.image }}
                quote={item.quote}
                source={item.source}
                sourceImage={{ ...item.sourceImage }}
              />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  )
}

TestimonialsSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.PropTypes.object),
  heading: PropTypes.string,
}

TestimonialsSection.defaultProps = {
  items: [],
  heading: '',
}

export default TestimonialsSection
