import React from 'react'
import PropTypes from 'prop-types'

import styles from './text-accordion.module.scss'
import Section from '../../components/section'
import Container from '../../components/container'
import RichText from '../../components/rich-text'
import Accordion from '../../components/accordion'
import Indent from '../../components/indent'

const TextAccordionSection = ({ heading, bodyText, items }) => (
  <Section className={styles.el}>
    <Container>
      <Indent>
        <div className={styles.el__container}>
          <div className={styles.el__side}>
            {heading && <h3 className={styles.el__heading}>{heading}</h3>}
          </div>
          <div className={styles.el__main}>
            {bodyText && (
              <div className={styles.el__bodyText}>
                <RichText render={bodyText.raw} />
              </div>
            )}
            <div className={styles.el__items}>
              <Accordion items={items} />
            </div>
          </div>
        </div>
      </Indent>
    </Container>
  </Section>
)

TextAccordionSection.propTypes = {
  heading: PropTypes.string,
  bodyText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.PropTypes.object),
}

TextAccordionSection.defaultProps = {
  heading: '',
  bodyText: '',
  items: [],
}

export default TextAccordionSection
