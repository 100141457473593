import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './grid.module.scss'

export const GridRow = props => {
  const { children, className, innerClassName, align, indent } = props
  const classes = classNames(styles.el__row, className)
  const innerClasses = classNames(styles.el__row__inner, innerClassName)
  return (
    <div className={classes} data-align={align}>
      <div className={innerClasses} data-indent={indent}>
        {children}
      </div>
    </div>
  )
}

GridRow.defaultProps = {
  children: undefined,
  className: '',
  innerClassName: '',
  indent: 0,
  align: 'default',
}

GridRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  indent: PropTypes.number,
  align: PropTypes.string,
}
