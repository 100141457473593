import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './image-frame-hover.module.scss'

const ImageFrameHover = ({ className, children, color, size }) => {
  const classes = classNames(styles.el, className)

  return (
    <div className={classes} data-color={color} data-size={size}>
      <div className={styles.el__top} />
      <div className={styles.el__right} />
      <div className={styles.el__bottom} />
      <div className={styles.el__left} />
      {children}
    </div>
  )
}

ImageFrameHover.defaultProps = {
  color: 'default',
  className: undefined,
  children: undefined,
  size: 'default',
}

ImageFrameHover.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
}

export default ImageFrameHover
