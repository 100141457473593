import React from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash-es'
import Link from '../../components/link'
import styles from './profiles-grid.module.scss'
import { Grid, GridRow, GridColumn } from '../../components/grid'
import ResponsiveImage from '../../components/responsive-image'
import Section from '../../components/section'
import Container from '../../components/container'
import Indent from '../../components/indent'

const ProfilesGridSection = ({ profiles, heading }) => {
  const sortedProfiles = sortBy(profiles, ['name'])

  return (
    <Section className={styles.el}>
      {heading && (
        <Container>
          <Indent>
            <h3 className={styles.el__heading}>{heading}</h3>
          </Indent>
        </Container>
      )}
      <Grid className={styles.el__grid}>
        <GridRow indent={1}>
          {sortedProfiles.map(profile => {
            const profileImage =
              profile.image && (profile.image.srcset || profile.image.src) ? (
                <ResponsiveImage {...profile.image} fillContainer />
              ) : null

            const profileTitle = (
              <>
                <span className={styles.el__item__name}>{profile.name}</span>
                <span className={styles.el__item__role}>{profile.role}</span>
              </>
            )

            return (
              <GridColumn
                className={styles.el__column}
                width="2"
                key={profile.route}
              >
                <div className={styles.el__item}>
                  <div className={styles.el__item__image}>
                    <Link to={profile.route}>{profileImage}</Link>
                  </div>
                  <div>
                    <Link to={profile.route}>{profileTitle}</Link>
                  </div>
                </div>
              </GridColumn>
            )
          })}
        </GridRow>
      </Grid>
    </Section>
  )
}

ProfilesGridSection.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.PropTypes.object),
  heading: PropTypes.string,
}

ProfilesGridSection.defaultProps = {
  profiles: [],
  heading: '',
}

export default ProfilesGridSection
