import slugify from 'slugify'
import { get } from 'lodash-es'
import { getResponsiveImage, IMAGE_TYPE_FIXED, IMAGE_TYPE_FLUID } from './get-responsive-image'

export const sectionSerializer = (data, location = {}) => {
  const sections = []

  data.forEach((s, sIndex) => {
    // section object
    const section = {
      type: s.slice_type,
      id: s.id,
    }

    // general -----------------------

    // move 'primary' level to root
    if (s.primary) {
      Object.keys(s.primary).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(s.primary, key)) {
          s[key] = s.primary[key]
        }
      })
      delete s.primary
    }

    // big image ---------------------

    if (s.slice_type === 'big_image') {
      section.images = []

      s.items.forEach((item, index) => {
        const image = getResponsiveImage(item.image)
        if (image.srcset) {
          section.images.push({
            id: `${sIndex}-big-image-${index}`,
            image,
            credit: get(item, 'credit'),
          })
        }
        return {}
      })
    }

    // body text ---------------------

    if (s.slice_type === 'body_text') {
      section.textContent = get(s, 'content')
    }

    // contact -----------------------

    if (s.slice_type === 'contact') {
      section.heading = get(s, 'heading')
      section.location = location
      section.submitLabel = get(s, 'submit_label')
      section.confirmationText = get(s, 'confirmation')
      section.formInputs = []

      s.items.forEach((field, index) => {
        section.formInputs.push({
          id: `${sIndex}-contact-${index}`,
          label: field.label,
          name: slugify(field.label, { lower: true }),
          type: field.type,
          required: field.required === 'true',
          options: field.options
            ? field.options.split(',').map(item => item.trim())
            : [],
        })
      })
    }

    // hero --------------------------

    if (s.slice_type === 'hero') {
      section.style = s.style ? slugify(s.style, { lower: true }) : undefined

      section.slides = []
      s.items.forEach((item, index) => {
        const image = getResponsiveImage(item.image)
        if (image && image.srcset) {
          section.slides.push({
            id: `${sIndex}-hero-${index}`,
            heading: get(item, 'richHeading'),
            description: get(item, 'description'),
            cta: {
              label: get(item, 'cta_label'),
              url: get(item.cta, 'url'),
              target: get(item.cta, 'target'),
            },
            image,
            imageMobile:
              getResponsiveImage(item.image_mobile) ||
              getResponsiveImage(item.image, 'fixed'),
            imageAlign: get(item, 'image_align')
              ? item.image_align.toLowerCase()
              : 'default',
          })
        }
      })
    }

    // intro -------------------------

    if (s.slice_type === 'intro') {
      section.heading = get(s, 'heading')
      section.description = get(s, 'description')
      section.image = getResponsiveImage(s.image)
      if (get(s, 'cta_label') && get(s, 'link.url')) {
        section.cta = {
          label: s.cta_label,
          link: s.link,
        }
      }
    }

    // logos -------------------------

    if (s.slice_type === 'logos') {
      section.heading = get(s, 'heading')
      section.items = []
      s.items.forEach((item, index) => {
        section.items.push({
          id: `${sIndex}-logos-${index}`,
          logo: item.logo,
          title: item.title,
          link: item.link,
        })
      })
    }

    // multi-image layout ------------

    if (s.slice_type === 'multi_image_layout') {
      section.background = s.background
        ? s.background.replace(/\s+/g, '-').toLowerCase()
        : undefined

      section.align = s.align
        ? s.align.replace(/\s+/g, '-').toLowerCase()
        : undefined

      section.images = []
      s.items.forEach((item, index) => {
        const image = getResponsiveImage(item.image)
        if (image.srcset) {
          section.images.push({
            id: `${sIndex}-multi-image-layout-${index}`,
            align: item.valign ? item.valign.toLowerCase() : 'top',
            credit: get(item, 'credit'),
            image,
          })
        }
      })
    }

    // testimonials ------------------

    if (s.slice_type === 'testimonials') {
      section.heading = s.heading

      section.items = []
      s.items.forEach((item, index) => {
        section.items.push({
          id: `${sIndex}-testimonials-${index}`,
          quote: get(item, 'quote'),
          video: item.video,
          image: getResponsiveImage(item.image),
          source: get(item, 'source'),
          sourceImage: getResponsiveImage(item.source_image),
        })
      })
    }

    // text accordion ----------------

    if (s.slice_type === 'text_accordion') {
      section.heading = get(s, 'heading')
      section.bodyText = get(s, 'body_text')

      section.items = []
      s.items.forEach((item, index) => {
        section.items.push({
          id: `${sIndex}-text-accordion-${index}`,
          heading: get(item, 'heading'),
          content: get(item, 'content'),
        })
      })
    }

    // text and image ----------------

    if (s.slice_type === 'text_and_image') {
      section.heading = get(s, 'heading')
      section.bodyText = get(s, 'body_text')
      section.fullColumnImage = get(s, 'full_column_image')
      section.layout = get(s, 'layout')
      if (get(s, 'cta_label') && get(s, 'link.url')) {
        section.cta = {
          label: s.cta_label,
          link: s.link,
        }
      }
      if (get(s, 'video.url')) {
        section.video = {
          url: s.video.url,
        }
      }
      section.images = []
      s.items.forEach((item, index) => {
        const image = getResponsiveImage(item.image, IMAGE_TYPE_FLUID)
        let icon = null
        if (item.image.thumbnails) {
          icon = getResponsiveImage(item.image.thumbnails.swatch, IMAGE_TYPE_FIXED)
        }
        section.images.push({
          id: `${sIndex}-text-and-image-${index}`,
          ...image,
          icon,
        })
      })
    }

    // add to output array -----------
    sections.push(section)
  })

  return sections
}
