import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import smoothscroll from 'smoothscroll-polyfill'

const ScrollLink = ({ className, children, to }) => {
  const isClient = typeof window === 'object'

  useEffect(() => {
    smoothscroll.polyfill()
  })

  const handleClick = () => {
    if (isClient) {
      window.scroll({ top: to, behavior: 'smooth' })
    }
  }

  return (
    <button type="button" className={className} onClick={handleClick}>
      {children}
    </button>
  )
}

ScrollLink.defaultProps = {
  className: '',
  children: undefined,
  to: 0,
}

ScrollLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.number,
}

export default ScrollLink
