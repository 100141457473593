import React, { useState, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import useDimensions from 'react-use-dimensions'

import styles from './hero.module.scss'
import HeroSlide from './hero-slide'
import { CarouselItem } from '../../components/carousel'
import Section from '../../components/section'
import ScrollLink from '../../components/scroll-link'
import ArrowSvg from './images/arrow-down.inline.svg'

const HeroSection = ({ slides, scrollPrompt }) => {
  const [transitionedIn, setTransitionedIn] = useState(false)
  const [elementTop, setElementTop] = useState(0)
  const ref = useRef(null)
  const { scrollY } = useViewportScroll()
  const y = useTransform(scrollY, [elementTop, elementTop - 50], [0, 5], {
    clamp: false,
  })
  const [spacerRef, { height }] = useDimensions() // get height

  const carouselVariants = {
    hidden: { y: '50%' },
    visible: {
      y: '0%',
      transition: { duration: 1, delay: 0, ease: 'backInOut' },
    },
  }

  const overlayVariants = {
    hidden: { scaleY: 1 },
    visible: {
      scaleY: 0,
      transition: { duration: 1, delay: 0, ease: 'backInOut' },
    },
  }

  const transitionIn = () => {
    setTransitionedIn(true)
  }

  useLayoutEffect(() => {
    const element = ref.current
    setElementTop(element.offsetTop)
  }, [ref])

  const slidesOutput = slides.map(slide => {
    return (
      <CarouselItem key={slide.id} className={styles.el__item}>
        <HeroSlide
          image={slide.image}
          imageAlign={slide.imageAlign}
          imageMobile={slide.imageMobile}
          heading={slide.heading}
          description={slide.description}
          cta={slide.cta}
        />
      </CarouselItem>
    )
  })

  return (
    <Section
      className={styles.el}
      transitionIn={transitionIn}
      scrollEnabled={false}
    >
      <div className={styles.el__spacer} ref={spacerRef} />
      <div className={styles.el__container} ref={ref}>
        {scrollPrompt && (
          <div className={styles.el__scroll}>
            <div className={styles.el__scroll__container}>
              <ScrollLink className={styles.el__scroll__button} to={height}>
                <div className={styles.el__scroll__button__icon}>
                  <ArrowSvg />
                </div>
              </ScrollLink>
            </div>
          </div>
        )}
        <motion.div className={styles.el__parallax} style={{ y }}>
          <motion.div
            className={styles.el__carousel}
            animate={transitionedIn ? 'visible' : 'hidden'}
            variants={carouselVariants}
          >
            {slides.length > 1 ? (
              <div className={styles.el__carousel_slide}>{slidesOutput}</div>
            ) : (
              slidesOutput
            )}
          </motion.div>
          <motion.div
            className={styles.el__overlay}
            animate={transitionedIn ? 'visible' : 'hidden'}
            variants={overlayVariants}
          />
        </motion.div>
      </div>
    </Section>
  )
}

HeroSection.defaultProps = {
  slides: [],
  scrollPrompt: true,
}

HeroSection.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      headingText: PropTypes.object,
      description: PropTypes.object,
      image: PropTypes.object,
      imageMobile: PropTypes.object,
      link: PropTypes.object,
      link_label: PropTypes.string,
    })
  ),
  scrollPrompt: PropTypes.bool,
}

export default HeroSection
