import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { get } from 'lodash-es'
import mergePreviewData from '../utils/merge-preview-data'
import FlexibleTemplate from '../../templates/flexible'
import { sectionSerializer } from '../utils/section-serializer'

const FlexiblePrismic = ({ data, location }) => {
  const { prismicFlexible } = mergePreviewData(data)
  const sections = sectionSerializer(prismicFlexible.data.body, location)
  const borderBottom = get(prismicFlexible, 'data.newsletter_signup') === 'true'
  const topPadding = get(prismicFlexible, '[0].slice_type') === 'hero'

  return (
    <FlexibleTemplate
      sections={sections}
      borderBottom={borderBottom}
      topPadding={topPadding}
    />
  )
}

FlexiblePrismic.defaultProps = {
  data: {},
  location: {},
}

FlexiblePrismic.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default FlexiblePrismic

export const pageQuery = graphql`
  query FlexibleByUid($uid: String!, $lang: String!) {
    prismicFlexible(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      lang
      type
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        title
        newsletter_signup
        meta_title
        meta_description
        canonical_url
        card_description
        card_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        card_image_square {
          localFile {
            childImageSharp {
              fixed(width: 400, height: 400) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        card_title
        body {
          ... on PrismicFlexibleBodyHero {
            id
            slice_type
            items {
              richHeading: heading {
                html
                raw
                text
              }
              description {
                html
                raw
                text
              }
              cta_label
              cta {
                url
                target
              }
              image {
                localFile {
                  id
                  uid
                  childImageSharp {
                    fluid(maxWidth: 1440, quality: 85, toFormat: JPG) {
                      ...GatsbyImageSharpFluid
                    }
                    fixed(height: 800, width: 800, quality: 85, toFormat: JPG) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicFlexibleBodyBigImage {
            id
            slice_type
            items {
              image {
                localFile {
                  id
                  uid
                  childImageSharp {
                    fluid(maxWidth: 2000, quality: 85) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              credit {
                html
                raw
              }
            }
          }
          ... on PrismicFlexibleBodyBodyText {
            id
            slice_type
            primary {
              content {
                raw
                html
                text
              }
            }
          }
          ... on PrismicFlexibleBodyContact {
            id
            slice_type
            primary {
              heading
              submit_label
              confirmation {
                html
              }
            }
            items {
              label
              type
              required
              options
            }
          }
          ... on PrismicFlexibleBodyLogos {
            id
            slice_type
            primary {
              heading
            }
            items {
              logo {
                url
              }
              title
              link {
                url
                link_type
                target
              }
            }
          }
          ... on PrismicFlexibleBodyTestimonials {
            id
            slice_type
            primary {
              heading
            }
            items {
              quote {
                html
                raw
              }
              video {
                url
              }
              image {
                localFile {
                  id
                  uid
                  childImageSharp {
                    fluid(maxWidth: 2000, quality: 85) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              source {
                html
                raw
              }
              source_image {
                localFile {
                  id
                  uid
                  childImageSharp {
                    fluid(maxWidth: 2000, quality: 85) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicFlexibleBodyTextAccordion {
            id
            slice_type
            primary {
              heading
              body_text {
                html
                raw
              }
            }
            items {
              heading
              content {
                html
                raw
              }
            }
          }
          ... on PrismicFlexibleBodyTextAndImage {
            id
            slice_type
            primary {
              heading
              body_text {
                html
                raw
              }
              cta_label
              link {
                url
                target
                link_type
              }
              layout
              full_column_image
              video {
                url
              }
            }
            items {
              image {
                localFile {
                  id
                  uid
                  childImageSharp {
                    fluid(maxWidth: 480, quality: 95, toFormat: JPG) {
                      ...GatsbyImageSharpFluid
                    }
                    fixed(height: 120, width: 120, quality: 95, toFormat: JPG) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                thumbnails {
                  swatch {
                    localFile {
                      id
                      uid
                      childImageSharp {
                        fluid(maxWidth: 240, quality: 95, toFormat: JPG) {
                          ...GatsbyImageSharpFluid
                        }
                        fixed(
                          height: 120
                          width: 120
                          quality: 95
                          toFormat: JPG
                        ) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicFlexibleBodyIntro {
            id
            slice_type
            primary {
              heading
              description {
                html
                raw
              }
              cta_label
              link {
                url
                link_type
                target
              }
            }
          }
        }
      }
    }
  }
`
