import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AccordionItem from './accordion-item'
import styles from './accordion.module.scss'

const Accordion = ({ items }) => {
  // this approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [open, setOpen] = useState(false)

  return (
    <div className={styles.el}>
      {items.map((item, index) => (
        <AccordionItem
          key={item.id}
          id={index}
          heading={item.heading}
          content={item.content}
          open={open}
          setOpen={setOpen}
        />
      ))}
    </div>
  )
}

Accordion.defaultProps = {
  items: [],
}

Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.PropTypes.object),
}

Accordion.propTypes = {}

export default Accordion
