import React from 'react'
import PropTypes from 'prop-types'
import styles from './featured-projects.module.scss'
import Container from '../../components/container'
import Section from '../../components/section'
import FeaturedProjectItem from './featured-project-item'

const FeaturedProjectsSection = ({ projects }) => (
  <Section className={styles.el}>
    <Container>
      <div className={styles.el__wrapper}>
        {projects.map(project => (
          <FeaturedProjectItem project={project} key={project.id} />
        ))}
      </div>
    </Container>
  </Section>
)

FeaturedProjectsSection.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.PropTypes.object),
}

FeaturedProjectsSection.defaultProps = {
  projects: [],
}

export default FeaturedProjectsSection
